<template>
  <v-select
    v-model="curLang"
    :items="langs"
    :label="$t('common.lang')"
    dense
    light
  >
    <template v-slot:selection="{ item }">
      <v-img
        max-width="25px"
        :alt="item"
        :key="item"
        :src="getLangImage(item)"
        class="mr-2"
      />
      {{ item }}
    </template>

    <template v-slot:item="{ item }">
      <v-img
        max-width="25px"
        :alt="item"
        :src="getLangImage(item)"
        class="mr-2"
      />
      {{ item }}
    </template>
  </v-select>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "LangSwitch",

  props: {
    icon: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      curLang: "en",
      langs: ["en", "sv"],
    };
  },

  computed: {
    ...mapState("users", ["userCompany"]),
    ...mapState("languages", { language_store: "language" }),
  },

  methods: {
    ...mapActions("languages", ["setLanguage"]),
    getLangImage(lang) {
      return "/lang/" + lang + ".png";
    },

    getIcon() {
      if (this.icon) {
        return "mdi-code-string";
      }

      return "";
    },
  },

  mounted() {
    if (this.language_store) {
      this.curLang = this.language_store;
    } else if (this.userCompany) {
      this.curLang = this.userCompany.country;
    } else {
      this.curLang = this.langs[0];
    }
  },

  watch: {
    curLang(newVal) {
      // Use language js to change language
      this.setLanguage(newVal);
      this.$emit("update-lang", newVal);
    },
  },
};
</script>
